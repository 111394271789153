import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
// import getVideoId from 'get-video-id'
import ytIcon from '../images/youtube-enlarge-icon.png'

const VideosTemplate = ({ data }) => {
  console.log('data', data)
  return (
    <Layout>
      <div className="about-us-wrapper">
        {' '}
        <section style={{ backgroundColor: '#fff', padding: '150px 0 170px' }}>
          <h1 className="ml-4 black-brightness text-center mb-5">
            {data.post.title}
          </h1>
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div>
                  <small className="mb-5">
                    To view videos full screen, click on the{' '}
                    <img src={ytIcon} alt="icon" /> icon. Press esc to go back
                    to grid view.
                  </small>
                  <iframe
                    width="100%"
                    height="500"
                    src={`https://www.youtube.com/embed/${data.post.videourl}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    sameorigin="true"
                    title={data.post.title}
                    className="mb-0"
                    style={{ borderRadius: '8px' }}
                  />

                  {data.post._rawDescription.map(a =>
                    a.children.map(b => {
                      return <h5>{b.text}</h5>
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default VideosTemplate

export const VidTemplateQuery = graphql`
  query VidTemplateQuery($id: String!) {
    post: sanityVideo(id: { eq: $id }) {
      id
      title
      _rawDescription
      videourl
      slug {
        current
      }
    }
  }
`
